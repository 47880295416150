import React from 'react'
import ReactDOM from 'react-dom'
import Header from '../../component/header'
import Portal from '../../component/portal'
import Fallback from '../../component/fallback'
import GTip from '../../component/gTip'
import FloatMenu from '../../component/floatMenu'
import { AppContext, defaultState } from '../../config/context'
import '../../common/util/channel'
import '../../common/util/polyfill'
import initReportEnv from '../../common/util/report'

initReportEnv('404')

const Fallback404 = Portal(Fallback, 'page-404-container')
const Type = window._inject_app_data_.type

class App extends React.Component {
    constructor() {
        super()
        this.updateUserInfo = user => {
            this.setState(state => ({
                user: {
                    ...state.user,
                    ...user
                }
            }))
        }
        this.state = {
            ...defaultState,
            updateUserInfo: this.updateUserInfo
        }
    }

    render() {
        return <AppContext.Provider value={this.state}>
            <Header></Header>
            <Fallback404 type={Type}></Fallback404>
            <GTip></GTip>
            <FloatMenu></FloatMenu>
        </AppContext.Provider>
    }
}

ReactDOM.render(<App />, document.getElementById('dokodemodoa'))