import React from 'react'
import './index.scss'
import { getPubgWindowData, getSteamWindowData, getSaleRank } from './service'
import { getImgUrl, hbsBoolean, goToHref, i18n } from '../../common/util'
import { AppContext } from '../../config/context'

class Fallback extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            rankList: [],
            pubgList: [],
            pubgMore: '',
            steamList: [],
            steamMore: '',
            rankType: ''
        }
    }

    componentDidMount() {
        this.chooseRankType('rank_month_sales')
        getPubgWindowData().then(({ content, targetUrl }) => this.setState({ pubgList: content, pubgMore: targetUrl }))
        getSteamWindowData().then(({ content, targetUrl }) => this.setState({ steamList: content, steamMore: targetUrl }))
    }

    chooseRankType = rankType => {
        if (rankType !== this.state.rankType) {
            this.setState({ rankType })
            getSaleRank(rankType).then(rankList => this.setState({ rankList }))
        }
    }

    renderRankItem = (item, index) => {
        const { lang } = this.context.user
        return <li className="G-rank-li" key={item.goodsId}>
            <a target="_self" href={`/p/${item.goodsId}.html`}>
                <img alt="商品图片" className="rank-item-img" src={getImgUrl(item.imgUrl)}></img>
                {
                    index < 3 && <span className="rank-item-value">{index + 1}</span>
                }
                <div className="rank-item-name">
                    <div className="content_inner">{i18n(item.name, lang)}</div>
                </div>
                <p className="price"><span className="yen">&yen;</span>{item.salePrice}</p>
            </a>
        </li>
    }

    renderWindowItem = item => {
        const { lang } = this.context.user
        return <li className="G-recommend-li" key={item.goodsId}>
            <a target="_self" href={`/p/${item.goodsId}.html`}>
                <img alt="商品图片" className="recommend-item-img" src={getImgUrl(item.imgUrl)}></img>
                <div className="recommend-item-info">
                    <p className="name">{i18n(item.name, lang)}</p>
                    <div className="ext">
                        {
                            hbsBoolean(item.tags) && <span className="tag">{i18n(item.tags[0].name, lang)}</span>
                        }
                        <span className="yen">&yen;</span>
                        <span className="price">{item.salePrice}</span>
                        {
                            hbsBoolean(item.primePrice) && <span className="op">{item.primePrice}</span>
                        }
                    </div>
                </div>
            </a>
        </li>
    }

    render() {
        const { type, style = {} } = this.props
        const { pubgList, pubgMore, steamList, steamMore, rankList, rankType } = this.state
        const { lang } = this.context.user
        return <div style={style}>
            {
                type === 2
                    ? <div className="G-warn-info">
                        <div className="G-warn-pic warn-none"></div>
                        <div className="G-warn-tip">
                            <p className="G-warn-tip-main">{i18n('暂无相关商品内容，看看其他游戏吧', lang)}</p>
                            <div className="G-warn-button-wrapper">
                                <button className="G-warn-button" onClick={() => goToHref('/')}>{i18n('返回首页', lang)}</button>
                            </div>
                        </div>
                    </div>
                    : type === 3
                        ? <div className="G-warn-info">
                            <div className="G-warn-pic warn-removed"></div>
                            <div className="G-warn-tip">
                                <p className="G-warn-tip-main">{i18n('此商品已下架或转移，看看其他游戏吧', lang)}</p>
                                <div className="G-warn-button-wrapper">
                                    <button className="G-warn-button" onClick={() => goToHref('/')}>{i18n('返回首页', lang)}</button>
                                </div>
                            </div>
                        </div>
                        : <div className="G-warn-info">
                            <div className="G-warn-pic warn-404"></div>
                            <div className="G-warn-tip warn-404">
                                <p className="G-warn-tip-main">{i18n('很抱歉，您所访问的页面不存在', lang)}</p>
                                <div className="G-warn-tip-detail">
                                    <p className="G-warn-tip-txt">· {i18n('您正在搜索的页面可能已经被删除、更改或暂不可用', lang)}</p>
                                    <p className="G-warn-tip-txt">· {i18n('检查网站地址格式、拼写是否有误', lang)}</p>
                                </div>
                                <div className="G-warn-button-wrapper">
                                    <button className="G-warn-button" onClick={() => goToHref('/')}>{i18n('返回首页', lang)}</button>
                                </div>
                            </div>
                        </div>
            }
            <div className="G-warn-recommend">
                <p className="G-recommend-header">{i18n('来看看这些有没有您喜欢的吧~', lang)}</p>
                <div className="G-recommend-wrapper">
                    <div className="G-recommend-rank">
                        <div className="recommend-rank-header">{i18n('销量排行', lang)}
                            <ul className="recommend-types">
                                <li className={rankType === 'rank_week_sales' ? 'recommend-type active' : 'recommend-type'} onClick={() => this.chooseRankType('rank_week_sales')}>周</li>
                                <li className={rankType === 'rank_month_sales' ? 'recommend-type active' : 'recommend-type'} onClick={() => this.chooseRankType('rank_month_sales')}>月</li>
                                <li className={rankType === 'rank_sales' ? 'recommend-type active' : 'recommend-type'} onClick={() => this.chooseRankType('rank_sales')}>{i18n('总', lang)}</li>
                            </ul>
                        </div>
                        <ul className="G-rank-ul">
                            {
                                rankList.map(this.renderRankItem)
                            }
                        </ul>
                    </div>
                    <div className="G-recommend-window">
                        <div className="G-recommend-title">
                            <span className="title">{i18n('国游专区', lang)}</span>
                            <span className="des">{i18n('超值特惠 限时抢购', lang)}</span>
                            {
                                hbsBoolean(pubgMore) && <a target="_self" href={pubgMore}>
                                    <span className="link">{i18n('进入专区', lang)}</span>
                                </a>
                            }
                        </div>
                        <ul className="G-recommend-ul">
                            {
                                pubgList.map(this.renderWindowItem)
                            }
                        </ul>
                        <div className="G-recommend-title last">
                            <span className="title">{i18n('STEAM精品', lang)}</span>
                            <span className="des">{i18n('热门游戏 超值低价', lang)}</span>
                            {
                                hbsBoolean(steamMore) && <a target="_self" href={steamMore}>
                                    <span className="link">{i18n('进入专区', lang)}</span>
                                </a>
                            }
                        </div>
                        <ul className="G-recommend-ul">
                            {
                                steamList.map(this.renderWindowItem)
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    }
}

Fallback.contextType = AppContext
export default Fallback