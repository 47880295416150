import { httpGet } from '../../common/util'

const getWindowData = (ename) => {
    return httpGet({
        url: '/goods/window/data',
        data: {
            ename
        }
    }).then(({ content, targetUrl }) => {
        return {
            content: content.filter(i => i.type === 1).sort((a, b) => b.sort - a.sort).slice(0, 5),
            targetUrl
        }
    })
}

export const getPubgWindowData = () => {
    return getWindowData('index_pubg')
}

export const getSteamWindowData = () => {
    return getWindowData('index_steam')
}

export const getSaleRank = (type) => {
    return httpGet({
        url: '/goods/rank/list',
        data: {
            type,
            pageNum: 1,
            pageSize: 4
        }
    }).then(({ records }) => {
        records.forEach(r => {
            r.goodsPic && (r.imgUrl = r.goodsPic)
        })
        return records
    })
}
